import React from 'react'
import { graphql } from 'gatsby'
import { Container, Flex, Box } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import Header from '@solid-ui-blocks/Header/Block01'
import FlexContent from '@solid-ui-components/FlexContent'
import ContentButtons from '@solid-ui-components/ContentButtons'
import ContentText from '@solid-ui-components/ContentText'
import { normalizeBlockContentNodes } from '@blocks-helpers'

const NotFoundPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  const text = content['404']['text'];
  const buttons = content['404']['buttons'];

  return (
    <Layout {...props}>
      <Seo title='Home' />
      <Header content={content['header']} />
      <Container sx={{ height: '100%' }}>
        <Flex
          sx={{
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <FlexContent>
            <Box sx={{ textAlign: 'center' }}>
              <ContentText content={text} />
              <Divider space={3} />
              <ContentButtons content={buttons} />
            </Box>
          </FlexContent>
        </Flex>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query page404BlockContent {
    allBlockContent(filter: { page: { in: ["site/404", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default NotFoundPage
